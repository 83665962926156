import clsx from "clsx";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { isBrowser } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { useSwipeable } from "react-swipeable";
import useStateRef from "react-usestateref";
import Footer from "../components/footer/Footer";
import Arrow from "../components/icons/Arrow";
import Information from "../components/information/Information";
import Loader from "../components/loader/Loader";
import Nav from "../components/nav/Nav";
import Seo from "../components/seo/Seo";
import Slide from "../components/Slide";
import { useInfoStore, useSlideStore } from "../state/useStore";

const colours = {
	red: "hsla(16, 84%, 55%, 1.00)",
	green: "hsla(99, 97%, 66%, 1.00)",
	blue: "hsla(233, 91%, 61%, 1.00)",
	transparent: "hsla(233, 91%, 61%, 0.00)",
	magenta: "hsla(308, 79%, 61%, 1.00)",
	cyan: "hsla(173, 93%, 74%, 1.00)",
	purple: "hsla(256, 91%, 62%, 1.00)",
};

const Index = forwardRef(({ data: { carousel } }, ref) => {
	const count = carousel.nodes.length;
	const [ready, setReady] = useState(false);
	const [index, setIndex, indexRef] = useStateRef(1);
	const [active, setActive] = useState([]);
	const [nextIndex, setNextIndex] = useState(2);
	const [motionless, setMotionless] = useState(false);
	const [logoSize, setLogoSize] = useState({ width: 0, height: 0 });
	const direction = useSlideStore((state) => state.slideDirection);
	const setDirection = useSlideStore((state) => state.setSlideDirection);
	const infoVisible = useInfoStore((state) => state.visible);

	const [arrowDirection, setArrowDirection] = useState("default");
	const [arrowWidth, setArrowWidth] = useState(0);
	const [animationColourStatus, setAnimationColourStatus] = useState("end");
	const [isCyclingColour, setIsCyclingColour] = useState(false);
	const arrowRef = useRef(null);
	const navLeftRef = useRef(null);
	const navRightRef = useRef(null);
	const logoRef = useRef(null);
	const isLandscape = useMediaQuery({ query: "(min-width: 768px)" });
	const marginBreakpointLandscape = useMediaQuery({ query: "(min-width: 640px)" });

	useEffect(() => {
		const getLogoSize = () => {
			const margin = marginBreakpointLandscape ? 32 : 16;
			setLogoSize({
				width: logoRef.current.offsetWidth + margin * 2,
				height: logoRef.current.offsetHeight + margin * 2,
			});
		};
		window.addEventListener("resize", getLogoSize);
		if (logoRef.current) {
			getLogoSize();
		}
		return () => window.removeEventListener("resize", getLogoSize);
	}, [logoRef.current, marginBreakpointLandscape]);

	useEffect(() => {
		const range = Array.from({ length: count }, (_, i) => i + 1);
		let result = [];
		const n = 2;
		for (var i = index - n, len = range.length; i <= index + n; i++) {
			result.push(range[(i + len) % len]);
		}
		setActive(result);
	}, [index, count]);

	// Set Height
	useEffect(() => {
		const setHeight = () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
			setReady(true);
		};
		window.addEventListener("resize", setHeight);
		setHeight();
		return () => window.removeEventListener("resize", setHeight);
	}, []);

	// Forward Slide
	const increaseIndex = () => {
		if (indexRef.current < count) {
			setIndex((prevIndex) => prevIndex + 1);
			setNextIndex((prevIndex) => (index + 1 === count ? 1 : prevIndex + 1));
		} else {
			setIndex(1);
			setNextIndex(2);
		}
	};

	// Back Slide
	const decreaseIndex = () => {
		if (indexRef.current !== 1) {
			setIndex((prevIndex) => prevIndex - 1);
			setNextIndex(index);
		} else {
			setIndex(count);
			setNextIndex(1);
		}
	};

	// Keyboard
	useEffect(() => {
		const handleKeyboard = (e) => {
			if (e.key === "ArrowLeft") {
				navLeftRef.current.click();
			}
			if (e.key === "ArrowRight") {
				navRightRef.current.click();
			}
		};
		window.addEventListener("keydown", handleKeyboard);
		return () => window.removeEventListener("keydown", handleKeyboard);
	}, []);

	// Cycle Colours
	useEffect(() => {
		if (isCyclingColour && animationColourStatus !== "end") {
			const timer = setInterval(() => {
				switch (animationColourStatus) {
					case "end":
						return setAnimationColourStatus("cycle1");
					case "cycle1":
						return setAnimationColourStatus("cycle2");
					case "cycle2":
						return setAnimationColourStatus("cycle3");
					case "cycle3":
						return setAnimationColourStatus("cycle1");
					default:
						return null;
				}
			}, 15000);
			return () => clearInterval(timer);
		}
		if (isCyclingColour && animationColourStatus === "end") {
			const timer = setInterval(() => {
				setAnimationColourStatus("cycle1");
			}, 10000);
			return () => clearInterval(timer);
		}
	}, [isCyclingColour, animationColourStatus]);

	// Motionless
	useEffect(() => {
		const reset = () => setMotionless(false);
		window.addEventListener("mousemove", reset);
		window.addEventListener("click", reset);
		return () => {
			window.removeEventListener("mousemove", reset);
			window.removeEventListener("click", reset);
		};
	}, []);

	// Auto Advance Timer
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (!motionless) {
				setMotionless(true);
			}
		}, 4000);
		return () => clearTimeout(timeout);
	}, [motionless]);

	// Auto Advance
	useEffect(() => {
		const timeout = setInterval(() => {
			if (motionless) {
				increaseIndex();
			}
		}, 4000);
		return () => clearInterval(timeout);
	}, [motionless]);

	const swipeHandler = useSwipeable({
		onSwipedUp: () => navRightRef.current.click(),
		onSwipedDown: () => navLeftRef.current.click(),
		onSwipedLeft: () => navRightRef.current.click(),
		onSwipedRight: () => navLeftRef.current.click(),
	});

	const sizePos = useCallback(
		(size) => {
			if (typeof window === "undefined") {
				return {};
			}
			const full = size === "full" ? true : false;
			if (isLandscape && full) {
				const def = 0;
				const start = "100%";
				const end = "0%";
				return {
					type: "fullLandscape",
					width: "100%",
					height: "100%",
					default: {
						x: def,
					},
					forwards: {
						x: [start, start, end],
					},
					backwards: {
						x: start,
					},
					end: {
						x: end,
					},
				};
			}
			if (isLandscape && !full) {
				const def = window.innerWidth / 2;
				const start = "200%";
				const end = "100%";
				return {
					type: "halfLandscape",
					width: "50%",
					height: "100%",
					default: {
						x: def,
					},
					forwards: {
						x: [start, start, end],
					},
					backwards: {
						x: start,
					},
					end: {
						x: end,
					},
				};
			}
			if (!isLandscape && full) {
				const def = 0;
				const start = "100%";
				const end = "0%";
				return {
					type: "fullPortrait",
					width: "100%",
					height: "100%",
					default: {
						y: def,
					},
					forwards: {
						y: [start, start, end],
					},
					backwards: {
						y: start,
					},
					end: {
						y: end,
					},
				};
			}
			if (!isLandscape && !full) {
				const def = window.innerHeight / 2;
				const start = "200%";
				const end = "100%";
				return {
					type: "halfPortrait",
					width: "100%",
					height: "50%",
					default: {
						y: def,
					},
					forwards: {
						y: [start, start, end],
					},
					backwards: {
						y: start,
					},
					end: {
						y: end,
					},
				};
			}
		},
		[isLandscape]
	);

	const variantsColour = {
		start: {
			fill: colours["magenta"],
			color: colours["magenta"],
			stroke: colours["magenta"],
		},
		end: {
			fill: [colours["magenta"], colours["cyan"], colours["purple"], colours["magenta"]],
			color: [colours["magenta"], colours["cyan"], colours["purple"], colours["magenta"]],
			stroke: [colours["magenta"], colours["cyan"], colours["purple"], colours["magenta"]],
		},
		cycle1: {
			fill: [null, colours["purple"], colours["cyan"]],
			color: [null, colours["purple"], colours["cyan"]],
			stroke: [null, colours["purple"], colours["cyan"]],
			transition: { duration: 2, delay: 5 },
		},
		cycle2: {
			fill: [null, colours["magenta"], colours["purple"]],
			color: [null, colours["magenta"], colours["purple"]],
			stroke: [null, colours["magenta"], colours["purple"]],
			transition: { duration: 2, delay: 5 },
		},
		cycle3: {
			fill: [null, colours["cyan"], colours["magenta"]],
			color: [null, colours["cyan"], colours["magenta"]],
			stroke: [null, colours["cyan"], colours["magenta"]],
			transition: { duration: 2, delay: 5 },
		},
	};

	return (
		<div className={"fixed top-0 left-0 w-screen h-window selection:bg-magenta bg-blue "}>
			{ready && (
				<>
					<motion.div
						className={
							"w-screen h-window text-magenta fill-magenta stroke-magenta pointer-events-none"
						}
						variants={variantsColour}
						animate={animationColourStatus}
						initial={"start"}
						transition={{ duration: 2 }}
						onAnimationComplete={(definition) => {
							if (definition === "end") {
								setIsCyclingColour(true);
							}
						}}
					>
						<Loader colours={colours} ref={logoRef} />
						<Footer
							animationColourStatus={animationColourStatus}
							variantsColour={variantsColour}
						/>
						<Information logoSize={logoSize} />
					</motion.div>
					<motion.main
						className={"absolute w-full h-full top-0 left-0"}
						ref={ref}
						initial={{ y: 0 }}
						animate={{ y: 0 }}
						exit={{ y: 0 }}
						transition={{ delay: Infinity, duration: 1, type: "tween" }}
					>
						<nav
							{...swipeHandler}
							className={clsx(
								"fixed w-screen h-full top-0 left-0 flex justify-between z-nav",
								{
									"pointer-events-none": infoVisible,
								}
							)}
						>
							<button
								className={clsx("group h-full cursor-none focus:outline-none")}
								style={{
									width: `calc(50vw - ${arrowWidth / 2}px)`,
								}}
								onMouseEnter={() => setArrowDirection("left")}
								onMouseLeave={() => setArrowDirection("default")}
								onClick={() => {
									decreaseIndex();
									setDirection("backwards");
								}}
								aria-label={"Navigate backwards"}
								ref={navLeftRef}
							>
								<div
									className={
										"relative p-4 w-36 h-36 right-0 rotate-180 opacity-0 group-focus-visible:opacity-100"
									}
								>
									<Arrow />
								</div>
							</button>
							<button
								className={clsx(" group h-full cursor-none focus:outline-none")}
								style={{
									width: `calc(50vw - ${arrowWidth / 2}px)`,
								}}
								onMouseEnter={() => setArrowDirection("right")}
								onMouseLeave={() => setArrowDirection("default")}
								onClick={() => {
									increaseIndex();
									setDirection("forwards");
								}}
								aria-label={"Navigate forwards"}
								ref={navRightRef}
							>
								<div
									className={
										"relative p-4 w-36 h-36 right-0 opacity-0 group-focus-visible:opacity-100"
									}
								>
									<Arrow />
								</div>
							</button>
							{isBrowser && (
								<motion.span
									variants={variantsColour}
									animate={animationColourStatus}
									initial={"start"}
									transition={{ duration: 2 }}
									className={"absolute"}
								>
									<Nav
										ref={arrowRef}
										arrowDirection={arrowDirection}
										setArrowWidth={setArrowWidth}
										motionless={motionless}
									/>
								</motion.span>
							)}
						</nav>
						<section className={"absolute w-full h-full top-0 left-0 overflow-hidden"}>
							{carousel.nodes.map((slide, i) => {
								return (
									<Slide
										key={slide._id}
										id={slide._id}
										direction={direction}
										carouselIndex={index}
										carouselNextIndex={nextIndex}
										slideIndex={i + 1}
										count={count}
										landscape={slide.landscape[0]}
										portrait={slide.portrait[0]}
										sizePos={sizePos(slide.size)}
										isLandscape={isLandscape}
										active={active.includes(i + 1)}
										increaseIndex={increaseIndex}
									/>
								);
							})}
						</section>
					</motion.main>
				</>
			)}
		</div>
	);
});

export default Index;

export const Head = () => {
	const message = "(ಠ ͜ʖಠ) (ಠ ͜ʖಠ) (ಠ ͜ʖಠ) STUDIO LOWRIE ";
	const [title, setTitle] = useState(message);
	useEffect(() => {
		const interval = setInterval(() => {
			setTitle((prevText) => prevText.substring(1) + prevText.substring(0, 1));
		}, 200);
		return () => clearInterval(interval);
	}, []);
	return <Seo title={title} />;
};

export const query = graphql`
	{
		carousel: allSanityCarousel(sort: { order: ASC, fields: orderRank }) {
			nodes {
				_id
				size
				title
				landscape {
					... on SanityImageItem {
						_key
						_type
						alt
						...ImageWithPreview
					}
					... on SanityVideoItem {
						_key
						_type
						video
						aspect
					}
				}
				portrait {
					... on SanityImageItem {
						_key
						_type
						alt
						...ImageWithPreview
						asset {
							_id
						}
					}
					... on SanityVideoItem {
						_key
						_type
						video
						aspect
					}
				}
			}
		}
	}
`;
