import React from "react";
import Image from "gatsby-plugin-sanity-image";

const MediaImage = ({ image, alt }) => {
	return (
		<Image
			{...image}
			alt={alt || ""}
			width={2500}
			loading="eager"
			className={"w-full h-full object-cover select-none pointer-events-none"}
		/>
	);
};

export default MediaImage;
