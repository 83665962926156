import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useSlideStore } from "../state/useStore";
import MediaImage from "./media/MediaImage";
import MediaVideo from "./media/MediaVideo";

const Slide = ({
	id,
	carouselIndex,
	carouselNextIndex,
	slideIndex,
	count,
	direction,
	landscape,
	portrait,
	isLandscape,
	sizePos,
	active,
}) => {
	const [z, setZ] = useState(slideIndex);
	const [status, setStatus] = useState("default");
	const setCurrentSlide = useSlideStore((state) => state.setCurrentSlide);

	const hasLandscape = landscape ? true : false;
	const hasPortrait = portrait ? true : false;

	const landscapeVisible = (hasLandscape && isLandscape) || !hasPortrait;
	const portraitVisible = (hasPortrait && !isLandscape) || !hasLandscape;

	const duration = 1;

	const variants = {
		default: {
			...sizePos.default,
			zIndex: z,
			transition: { duration: 0 },
		},
		forwards: {
			...sizePos.forwards,
			zIndex: z,
			transition: { duration: duration, times: [0, 0, 1] },
		},
		backwards: {
			...sizePos.backwards,
			transition: { duration: duration },
			transitionEnd: {
				...sizePos.end,
				zIndex: z,
			},
		},
	};

	useEffect(() => {
		if (direction === "forwards" && carouselIndex === slideIndex) {
			setZ((prevState) => prevState + count);
			setStatus("forwards");
			// console.log(`ENTER ${title}`);
			return;
		}
		if (direction === "backwards" && carouselNextIndex === slideIndex) {
			setZ((prevState) => prevState - count);
			setStatus("backwards");
			// console.log(`REMOVE ${title}`);
			return;
		}
	}, [carouselIndex, carouselNextIndex, direction, slideIndex, count]);

	useEffect(() => {
		if (carouselIndex === slideIndex) {
			setCurrentSlide(id);
		}
	}, [carouselIndex, slideIndex, id, setCurrentSlide]);

	

	return (
		<motion.div
			className={"absolute top-0 left-0 overflow-hidden"}
			style={{ width: sizePos.width, height: sizePos.height }}
			initial={"hidden"}
			animate={status}
			transition={{ type: "tween" }}
			variants={variants}
			onAnimationComplete={() => {
				setStatus("default");
			}}
		>
			{active && landscapeVisible && (
				<div className={"w-full h-full overflow-hidden"}>
					{landscape._type === "imageItem" ? (
						<MediaImage image={landscape} alt={landscape.alt} />
					) : (
						<MediaVideo video={landscape.video} aspect={landscape.aspect} />
					)}
				</div>
			)}
			{active && portraitVisible && (
				<div className={"w-full h-full overflow-hidden"}>
					{portrait._type === "imageItem" ? (
						<MediaImage image={portrait} alt={portrait.alt} />
					) : (
						<MediaVideo video={portrait.video} aspect={portrait.aspect} />
					)}
				</div>
			)}
		</motion.div>
	);
};

export default Slide;
