import imageUrlBuilder from "@sanity/image-url";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { configuredSanityClient } from "../utils/SanityClient";

const builder = imageUrlBuilder(configuredSanityClient);

function urlFor(source) {
	return builder.image(source);
}

const Seo = ({ title, description, banner, pathname, children }) => {
	const { global } = useStaticQuery(getData);
	const defaultTitle = global.seoTitle;
	const defaultDescription = global.seoDescription;
	const defaultBanner = urlFor(global.seoImage.asset._id).width(1200).url();
	const siteUrl = "https://studiolowrie.com";

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		banner: banner || defaultBanner,
		url: `${siteUrl}${pathname || ``}`,
	};

	return (
		<>
			<title>{seo.title}</title>
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.banner} />

			<meta property="og:type" content="website" />
			<meta property="og:url" content={seo.url} />
			<meta property="og:title" content={seo.title} />
			<meta property="og:description" content={seo.description} />
			<meta property="og:image" content={seo.banner} />

			<meta property="twitter:card" content="summary_large_image" />
			<meta property="twitter:url" content={seo.url} />
			<meta property="twitter:title" content={seo.title} />
			<meta property="twitter:description" content={seo.description} />
			<meta property="twitter:image" content={seo.banner} />

			{children}
		</>
	);
};

export default Seo;

const getData = graphql`
	{
		global: sanityGlobal {
			seoTitle
			seoDescription
			seoImage {
				asset {
					_id
				}
			}
		}
	}
`;
