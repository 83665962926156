import { motion } from "framer-motion";
import React from "react";

const Background = ({ setLoading, colours }) => {
	const variantsBackground = {
		start: {
			background: colours["red"],
		},
		end: {
			background: [colours["red"], colours["green"], colours["blue"], colours["transparent"]],
		},
	};
	return (
		<motion.div
			initial={"start"}
			animate={"end"}
			variants={variantsBackground}
			onAnimationComplete={() => setLoading(false)}
			transition={{ duration: 2 }}
			className={"absolute w-full h-full bg-black top-0 left-0 -z-20"}
		></motion.div>
	);
};

export default Background;
