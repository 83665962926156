import { useEffect, useState } from "react";

export const useWindowSize = () => {
	const [size, setSize] = useState({ windowX: 0, windowY: 0 });
	useEffect(() => {
		const handleResize = () =>
			setSize({ windowX: window.innerWidth, windowY: window.innerHeight });
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return size;
};
