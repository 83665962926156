import getVideoId from "get-video-id";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/vimeo";
import { useWindowSize } from "../../hooks/useWindowSize";

const MediaVideo = ({ video, aspect }) => {
	const { windowX, windowY } = useWindowSize();
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const windowAspect = windowX / windowY;
		if (windowAspect >= aspect) {
			setWidth(windowX);
			setHeight(10000);
		} else {
			setWidth(10000);
			setHeight(windowY);
		}
	}, [windowX, windowY]);

	return (
		<div
			className={"absolute h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		>
			{video && (
				<ReactPlayer
					url={`https://vimeo.com/${getVideoId(video).id}`}
					width={"100%"}
					height={"100%"}
					playing={true}
					playsinline={true}
					muted={true}
					controls={false}
					loop={true}
					style={{
						objectFit: "cover",
					}}
				/>
			)}
		</div>
	);
};

export default MediaVideo;
