import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { useInfoStore, useSlideStore } from "../../state/useStore";

const Footer = () => {
	const currentSlide = useSlideStore((state) => state.currentSlide);
	const direction = useSlideStore((state) => state.slideDirection);
	const infoVisible = useInfoStore((state) => state.visible);
	const toggleInfo = useInfoStore((state) => state.toggleVisible);
	const { slides } = useStaticQuery(getData);

	return (
		<footer
			className={
				"absolute w-full h-max p-4 bottom-0 z-footer text-2xl sm:text-3xl md:text-4xl pointer-events-none md:p-6"
			}
		>
			<div className={"relative flex justify-between items-end top-0 sm:top-1 md:top-1"}>
				<div
					className={
						"relative flex w-full h-6 overflow-hidden leading-none sm:h-8 md:h-10"
					}
				>
					<AnimatePresence>
						{!infoVisible && (
							<motion.div
								className={"absolute w-max select-none"}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.3 }}
							>
								<AnimatePresence>
									{slides.nodes.map((slide, index) => {
										return currentSlide === slide._id ? (
											<motion.div
												key={slide._id}
												className={"absolute w-max select-none"}
												initial={{
													y: direction === "forwards" ? "100%" : "-100%",
												}}
												animate={{ y: 0 }}
												exit={{
													y: direction === "forwards" ? "-120%" : "120%",
												}}
												transition={{
													duration: 0.5,
													type: "spring",
													bounce: 0.4,
												}}
											>
												<h1>
													<span className={clsx("hidden", "lg:inline")}>
														{slide.title}
													</span>
													<span className={clsx("inline", "lg:hidden")}>
														{`${index + 1} / ${slides.nodes.length}`}
													</span>
												</h1>
											</motion.div>
										) : null;
									})}
								</AnimatePresence>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
				<button
					className={
						"group relative flex w-max h-6 leading-none focus:outline-none focus-visible:underline pointer-events-auto sm:h-8 md:h-10"
					}
					onClick={() => toggleInfo()}
				>
					{infoVisible ? "Back" : "Information"}
				</button>
			</div>
		</footer>
	);
};

export default Footer;

const getData = graphql`
	{
		slides: allSanityCarousel(sort: { order: ASC, fields: orderRank }) {
			nodes {
				_id
				title
			}
		}
	}
`;
