import clsx from "clsx";
import React, { forwardRef, useEffect } from "react";
import useCursorPosition from "../../hooks/useCursorPosition";
import Arrow from "../icons/Arrow";

const Arrows = forwardRef(({ arrowDirection, setArrowWidth, motionless }, ref) => {
	const { cursorX, cursorY } = useCursorPosition();

	useEffect(() => {
		const handleResize = () => setArrowWidth(ref.current.offsetWidth);
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<nav
			style={{
				top: `${cursorY}px`,
				left: `${cursorX}px`,
			}}
			className={clsx(
				"absolute pointer-events-none transition-opacity duration-500 opacity-0 origin-center",
				{ "opacity-100": !motionless }
			)}
			ref={ref}
		>
			<div
				className={clsx(
					"-translate-y-1/2 translate-x-0",
					{ "opacity-0": arrowDirection === "default" },
					{ "rotate-180 -translate-x-[100%]": arrowDirection === "right" }
				)}
			>
				<Arrow />
			</div>
		</nav>
	);
});

export default Arrows;
