import clsx from "clsx";
import { motion } from "framer-motion";
import React, { useState, forwardRef } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Lowrie from "../icons/Lowrie";
import Studio from "../icons/Studio";
import Background from "./Background";

const Loader = forwardRef((props, ref) => {
	const { colours } = props;
	const transition = { delay: 1, duration: 1 };
	const [loading, setLoading] = useState(true);
	const [moveRight, setMoveRight] = useState("default");
	const [moveLeft, setMoveLeft] = useState("default");
	const isLandscape = useMediaQuery({ query: "(min-width: 950px)" });
	const [landscape, setLandscape] = useState(useMediaQuery({ query: "(min-width: 950px)" }));

	useEffect(() => {
		isLandscape ? setLandscape(true) : setLandscape(false);
	}, [isLandscape]);

	const variantsLeft = {
		middle: {
			top: "50%",
			x: landscape ? "100%" : "50%",
			y: "-50%",
		},
		top: {
			top: 0,
			x: 0,
			y: 0,
		},
	};

	const variantsRight = {
		middle: {
			top: "50%",
			x: 0,
			y: "-50%",
		},
		top: {
			top: 0,
			x: landscape ? "100%" : "50%",
			y: 0,
		},
	};

	const variantsDodgeLeft = {
		default: {
			x: 0,
		},
		move: {
			x: "100%",
		},
	};

	const variantsDodgeRight = {
		default: {
			x: 0,
		},
		move: {
			x: "-100%",
		},
	};

	return (
		<header
			className={clsx(
				"absolute w-full h-full top-0 left-0 flex items-start text-8xl p-4 leading-none z-loader text-white pointer-events-none",
				"sm:p-6 md:p-8"
			)}
		>
			<div className={clsx("w-1/2 h-full")}>
				<motion.button
					key={landscape + "left"}
					className={clsx(
						"relative w-2/3 flex flex-grow-0 pr-[1vw] cursor-default pointer-events-auto top-1/2 translate-x-1/2 -translate-y-1/2 focus:outline-none",
						"md:w-1/2 md:translate-x-full"
					)}
					variants={variantsLeft}
					initial={loading ? "middle" : "top"}
					animate={"top"}
					transition={transition}
					onClick={() =>
						moveLeft === "move" ? setMoveLeft("default") : setMoveLeft("move")
					}
					onMouseEnter={() => setMoveLeft("move")}
					onMouseLeave={() => setMoveLeft("default")}
					onFocus={() => setMoveLeft("move")}
					onBlur={() => setMoveLeft("default")}
					ref={ref}
				>
					<motion.div
						variants={variantsDodgeLeft}
						animate={!loading ? moveLeft : "default"}
						initial={"default"}
					>
						<span>
							<Studio />
						</span>
					</motion.div>
				</motion.button>
			</div>
			<div className={clsx("w-1/2 h-full")}>
				<motion.button
					key={landscape + "right"}
					className={clsx(
						"relative w-2/3 flex flex-grow-0 pl-[1vw] cursor-default pointer-events-auto top-1/2 -translate-y-1/2 focus:outline-none",
						"md:w-1/2"
					)}
					variants={variantsRight}
					initial={loading ? "middle" : "top"}
					animate={"top"}
					transition={transition}
					onClick={() =>
						moveRight === "move" ? setMoveRight("default") : setMoveRight("move")
					}
					onMouseEnter={() => setMoveRight("move")}
					onMouseLeave={() => setMoveRight("default")}
					onFocus={() => setMoveRight("move")}
					onBlur={() => setMoveRight("default")}
				>
					<motion.div
						variants={variantsDodgeRight}
						animate={!loading ? moveRight : "default"}
						initial={"default"}
					>
						<span>
							<Lowrie />
						</span>
					</motion.div>
				</motion.button>
			</div>
			<Background setLoading={setLoading} colours={colours} />
		</header>
	);
});

export default Loader;
