import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { PortableText } from "@portabletext/react";
import { motion } from "framer-motion";
import clsx from "clsx";
import { useInfoStore } from "../../state/useStore";
import Image from "gatsby-plugin-sanity-image";
import Text from "../text/Text";

const LINK_STYLE =
	"underline decoration-from-font underline-offset-4 focus-visible:underline outline-none";

const components = {
	types: {
		imageItem: ({ value }) => (
			<div className={"relative mx-auto w-0 overflow-visible h-40 md:h-52 lg:h-60 xl:h-80"}>
				<div className={"absolute h-full aspect-[3/2] -translate-x-1/2"}>
					<Image
						{...value}
						alt={value.alt || ""}
						width={1000}
						className={"w-full h-full object-cover"}
					/>
				</div>
			</div>
		),
	},
	block: {
		normal: ({ children }) => <p>{children}</p>,
	},
	marks: {
		em: ({ children }) => (
			<em className="font-serif text-5xl sm:text-6xl md:text-7xl lg:text-8xl  xl:text-9xl">
				{children}
			</em>
		),
		blockLink: ({ value, children }) => {
			switch (value.type) {
				case "website":
					return (
						<LinkItem href={value?.link} external={true}>
							{children}
						</LinkItem>
					);
				case "telephone":
					return <LinkItem href={`tel:${value?.link}`}>{children}</LinkItem>;
				case "email":
					return <LinkItem href={`mailto:${value?.link}`}>{children}</LinkItem>;
				default:
					return null;
			}
		},
	},
};

const Information = ({ logoSize }) => {
	const visible = useInfoStore((state) => state.visible);
	const { about } = useStaticQuery(getData);
	const [offset, setOffset] = useState(0);
	const scrollRef = useRef();

	useEffect(() => {
		const handleScroll = () => {
			setOffset(scrollRef.current.scrollTop);
		};
		scrollRef.current.addEventListener("scroll", handleScroll);

		return () => {
			scrollRef.current.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<motion.section
			initial={{ y: "100%" }}
			animate={visible ? { y: 0 } : { y: "100%" }}
			transition={{ duration: 1, type: "tween" }}
			className={"relative w-full h-full bg-black z-info pointer-events-auto"}
		>
			<div className={"h-full w-full overflow-scroll"} ref={scrollRef}>
				<div
					className="float-left hidden md:block"
					style={{
						marginTop: `${offset}px`,
						width: `${logoSize.width}px`,
						height: `${logoSize.height}px`,
					}}
				></div>
				<div
					className="float-right hidden md:block"
					style={{
						marginTop: `${offset}px`,
						width: `${logoSize.width}px`,
						height: `${logoSize.height}px`,
					}}
				></div>
				<div className={clsx("p-8 pt-24  mx-auto sm:pt-32 md:pt-38 lg:pt-48 ")}>
					<Text
						className={
							"text-center [&>*:not(:last-child)]:mb-[2em] md:max-w-[35ch] lg:max-w-[40ch] m-auto"
						}
					>
						<PortableText
							value={about._rawAbout}
							components={components}
							onMissingComponent={false}
						/>
					</Text>
				</div>
			</div>
		</motion.section>
	);
};

export default Information;

const LinkItem = ({ href, children, className, external }) => {
	return external ? (
		<a
			href={href}
			className={clsx(LINK_STYLE, className)}
			target="_blank"
			rel="noreferrer noopener"
		>
			{children}
		</a>
	) : (
		<a href={href} className={clsx(LINK_STYLE, className)}>
			{children}
		</a>
	);
};

const getData = graphql`
	{
		about: sanityAbout {
			_id
			_rawAbout(resolveReferences: { maxDepth: 10 })
		}
	}
`;
