import create from "zustand";

export const useSlideStore = create((set) => ({
	currentSlide: "",
	slideDirection: "forwards",
	setCurrentSlide: (status) => set((state) => ({ currentSlide: status })),
	setSlideDirection: (status) => set((state) => ({ slideDirection: status })),
}));

export const useInfoStore = create((set) => ({
	visible: "",
	toggleVisible: (status) => set((state) => ({ visible: !state.visible })),
}));