import React from "react";

const Arrow = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100.83"
			height="201.66"
			viewBox="0 0 100.83 201.66"
			className={"h-full w-[25vw] max-w-[30vh] rotate-180 stroke-inherit"}
		>
			<polygon
				points="98 100.83 2 4.83 2 196.83 98 100.83"
				fill="none"
				strokeMiterlimit="15"
				vectorEffect="non-scaling-stroke"
				strokeWidth="4px"
			/>
		</svg>
	);
};

export default Arrow;
