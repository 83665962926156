import clsx from 'clsx'
import React from 'react'

const Text = ({children, className}) => {
  return (
		<div className={clsx("leading-none text-2xl sm:text-3xl md:text-4xl lg:text-5xl  xl:text-6xl", className)}>
			{children}
		</div>
  );
}

export default Text